<template>
  <div>
    <div>
      <div class="google__search-results">
        <div class="gcse-search"></div>
      </div>
    </div>
    <h6 class="ed-label mx-3" v-if="searchKeyword.length < 1 && !isSearched">
      You haven't searched anything yet.
    </h6>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SearchEngine',
  data() {
    return {
      isSearched: false,
      clickElement:
        '<span class="gcs-add-to-note ms-1 mt-1 "><i class="box-icon search-engine-note__icon bx bx-export" title="add to note"></i></span>',
      addToGraphElement:
        '<span class="gcs-add-to-note ms-1 mt-1 "><i class="box-icon search-engine-note__icon bx bx-cloud-snow" title="add to graph"></i></span>',
    };
  },

  computed: {
    searchKeyword() {
      return this.$store.state.current_selected_entity.word;
    },
  },

  created() {
    const googleSearchScript = document.createElement('script');
    googleSearchScript.setAttribute(
      'src',
      'https://cse.google.com/cse.js?cx=72f33ee999e7d44e3',
    );
    document.head.appendChild(googleSearchScript);
  },

  watch: {
    searchKeyword(newValue) {
      document.getElementById('gsc-i-id1').value = newValue;
      document.querySelector('.gsc-search-button.gsc-search-button-v2').click();
    },
  },

  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    window.__gcse = {
      searchCallbacks: {
        web: {
          rendered: () => {
            // get the search results list parent element
            const searchResults = document.querySelector(
              '.gsc-webResult .gsc-expansionArea',
            );
            const searchResultChildren = searchResults.children;
            this.isSearched = true;

            // iterate on search result children and modify each
            Array.from(searchResultChildren).forEach((elem) => {
              if (
                !elem.classList.contains('gsc-adBlockInvisible')
                && !elem.classList.contains('gsc-adBlockNoHeight')
              ) {
                const resultLink = elem.querySelector('a');
                const resultLinkParent = resultLink.parentElement;

                // create a new button element
                const addToNoteButtonTemplate = this.clickElement;
                // create a add to graph element
                const addToGraphElementTemplate = this.addToGraphElement;

                // parse the button through dom parser to create html node
                const parser = new DOMParser();
                const doc = parser.parseFromString(
                  addToNoteButtonTemplate,
                  'text/html',
                );
                const doc2 = parser.parseFromString(
                  addToGraphElementTemplate,
                  'text/html',
                );
                const addToNoteButton = doc.body.firstChild;
                const addToGraphButton = doc2.body.firstChild;
                // add to Graph event Listener
                addToGraphButton.addEventListener('click', () => {
                  const linkUrl = resultLink.href;
                  // const linkTitle = resultLink.innerHTML;

                  // create a new note
                  const value = {
                    id: linkUrl,
                    label: resultLink.innerText,
                    noteUrl: linkUrl,
                    type: 'page',
                    timestamp: moment().format(),
                  };
                  this.$store.dispatch('gmodule/addSearchToGraph', value);
                });
                // add to note Event Listener
                addToNoteButton.addEventListener('click', () => {
                  const linkUrl = resultLink.href;
                  const linkTitle = resultLink.innerHTML;

                  // create a new note
                  const value = {
                    note: linkTitle,
                    noteUrl: linkUrl,
                    type: 'note',
                    timestamp: moment().format(),
                  };
                  const verb = 'NOTED';
                  const actor = this.$store.state.auth.user;
                  const data = {
                    actor,
                    verb,
                    value,
                    dataSource: 'google',
                  };
                  this.$store.dispatch('addToNote', data);
                });

                // add button to the search result
                resultLinkParent.appendChild(addToNoteButton);
                resultLinkParent.appendChild(addToGraphButton);
              }
            });
          },
        },

        image: {
          rendered: () => {
            // get the search results list parent element
            const searchResults = document.querySelector(
              '.gsc-imageResult .gsc-expansionArea',
            );

            // iterate on search result children and modify each
            Array.from(searchResults.children).forEach((elem) => {
              const imageElement = elem.querySelector('img');
              // you should not do this ever again
              const imageElementParent = imageElement.parentElement.parentElement.parentElement
                .parentElement.parentElement;

              // create a new button for adding Note
              const addToNoteButtonTemplate = this.clickElement;
              // create a new button for adding Graph
              const addToGraphButtonTemplate = this.addToGraphElement;

              // parse the button through dom parser to create html node
              const parser = new DOMParser();
              const doc = parser.parseFromString(
                addToNoteButtonTemplate,
                'text/html',
              );
              const doc2 = parser.parseFromString(
                addToGraphButtonTemplate,
                'text/html',
              );
              const addToNoteButton = doc.body.firstChild;
              const addToGraphButton = doc2.body.firstChild;
              // event listener for add to Graph
              addToGraphButton.addEventListener('click', () => {
                const imageUrl = imageElement.src;
                const imageTitle = imageElement.alt;

                // create a new note
                const value = {
                  id: imageTitle,
                  imageAlt: imageTitle,
                  note: imageUrl,
                  noteUrl: imageUrl,
                  type: 'image',
                  timestamp: moment().format(),
                };
                this.$store.dispatch('gmodule/addSearchToGraph', value);
              });
              // event listener for add to Note
              addToNoteButton.addEventListener('click', () => {
                const imageUrl = imageElement.src;
                const imageTitle = imageElement.alt;

                // create a new note
                const value = {
                  imageAlt: imageTitle,
                  note: imageUrl,
                  type: 'image',
                  timestamp: moment().format(),
                };
                const verb = 'NOTED';
                const actor = this.$store.state.auth.user;
                const data = {
                  actor,
                  verb,
                  value,
                  dataSource: 'google',
                };
                this.$store.dispatch('addToNote', data);
              });

              // add Note button to the search result
              imageElementParent.appendChild(addToNoteButton);
              // add Graph button to the search result
              imageElementParent.appendChild(addToGraphButton);
            });
          },
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/abstracts/variables";

.gcs-add-to-note {
  i {
    font-size: 1.5rem !important;
    color: $gray !important;
    transform: translateY(0.1875em);
  }
}
</style>
